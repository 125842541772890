import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { userServiceAtom } from "scmp-app/lib/user-service/atoms";

import { useSnapshotState } from ".";

const followedThreshold = 3;

export const useMyNews = () => {
  const { follows = [] } = useAtomValue(userServiceAtom);

  const { value: isVisitedMyNewsPage } = useFallbackStorageValue("isVisitedMyNewsPage", {
    initializeWithValue: true,
    parse: raw => raw,
  });

  const [dataReference] = useSnapshotState(follows);
  // Check the followed value by snapshot
  const initialHasEnoughFollowedItems = useMemo(
    () => Object.values(dataReference ?? {}).flat().length >= followedThreshold,
    [dataReference],
  );

  // Check the followed value by variable list
  const hasEnoughFollowedItems = useMemo(() => follows.length >= followedThreshold, [follows]);

  return {
    hasEnoughFollowedItems,
    initialHasEnoughFollowedItems,
    isVisitedMyNewsPage,
  };
};
