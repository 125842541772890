import { atom } from "jotai";
import type { KeyedMutator } from "swr";

export type State = {
  bookmarks?: string[];
  follows?: string[];
  mutate?: KeyedMutator<Omit<State, "mutate"> | undefined>;
};

export const userServiceAtom = atom<State>({});
