import * as scmpAccount from "@product/scmp-account-sdk";
import { getIsInWebView, notEmpty, safeSetLocalStorage } from "@product/scmp-sdk";
import { useSyncedRef } from "@react-hookz/web";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useState } from "react";

import { config } from "shared/data";
import { maskValue, registerEmailCookieAtom } from "shared/lib/account";

import type { LoginDialog } from "scmp-app/components/login-dialog/atoms";
import { loginDialogAtom } from "scmp-app/components/login-dialog/atoms";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type {
  Event,
  LoginPopImpressionEventParameters,
} from "scmp-app/components/tracking/google-analytics-4/types";
import { yieldToMain } from "scmp-app/lib/yield";

type SetLoginStateProps = Partial<Omit<LoginDialog, "isVisible">> & {
  destination: string;
  ga4CustomParameter?: LoginPopImpressionEventParameters;
};

type TrackEventProps = {
  ga4CustomParameter?: LoginPopImpressionEventParameters;
  isShow: boolean;
};

export const useLoginDialogStateHelper = () => {
  const [state, setState] = useAtom(loginDialogAtom);

  const trackEvent = useCallback(({ ga4CustomParameter, isShow }: TrackEventProps) => {
    if (!isShow || !notEmpty(ga4CustomParameter)) return;

    yieldToMain({ priority: "background" })
      .then(() => {
        const data: Event = {
          action: "imp",
          category: "login",
          customized_parameters: ga4CustomParameter,
          subcategory: "popup",
        };
        sendGA4Tracking(data);
      })
      .catch((error: unknown) => {
        console.error("Error sending GA4 tracking event", error);
      });
  }, []);

  const closeLoginDialog = useCallback(() => {
    setState(current => ({
      ...current,
      description: "",
      destination: "",
      emailLoginTitle: "Sign In / Register",
      isVisible: false,
      title: "Sign In / Register",
    }));
    trackEvent({ ga4CustomParameter: undefined, isShow: false });
  }, [setState, trackEvent]);

  const openLoginDialog = useCallback(
    (loginState: SetLoginStateProps) => {
      setState({
        ...loginState,
        description: loginState.description ?? "",
        emailLoginTitle: "Sign In / Register",
        isVisible: true,
        title: loginState.title ?? "Sign In / Register",
        variant: "dialog",
      });
      trackEvent({ ga4CustomParameter: loginState.ga4CustomParameter, isShow: true });
    },
    [setState, trackEvent],
  );

  return {
    closeLoginDialog,
    loginDialogState: state,
    openLoginDialog,
    setLoginDialogState: setState,
  };
};

const useScmpAccountEventHandlers = () => {
  const { loginDialogState: state, setLoginDialogState: setState } = useLoginDialogStateHelper();

  const updateRegisterEmailCookie = useSetAtom(registerEmailCookieAtom);
  const [unsubscribeFunctions, setUnsubscribeFunctions] = useState<(() => void)[]>([]);

  const attachEventHandlers = useCallback(() => {
    // Clear localstorage for that wall type
    if (state.wallType) {
      localStorage.removeItem(state.wallType);
    }

    const allUnsubscribeFunctions: (() => void)[] = [];

    const unsubscribeEmailRegistrationSuccess = scmpAccount.on(
      scmpAccount.EVENT.EMAIL_REGISTRATION_SUCCESS,
      (payload: scmpAccount.EmailRegistrationSuccessEventPayload) => {
        updateRegisterEmailCookie(payload.email);
      },
    );
    if (typeof unsubscribeEmailRegistrationSuccess === "function") {
      allUnsubscribeFunctions.push(unsubscribeEmailRegistrationSuccess);
    }

    const unsubscribeEmailRegistrationCompleted = scmpAccount.on(
      scmpAccount.EVENT.EMAIL_REGISTRATION_COMPLETED,
      (payload: scmpAccount.EmailRegistrationCompletedPayload) => {
        const getRegisterCallbackUrl = () => {
          const url = new URL(window.location.href);
          const centralizeRegisterUrl = new URL(
            `${config.account.registerCallbackUrl}${maskValue(payload.email)}`,
          );
          centralizeRegisterUrl.searchParams.set("source", "pwa");
          if (state.destination)
            centralizeRegisterUrl.searchParams.set("destination", state.destination);

          url.searchParams.set("destination", encodeURIComponent(centralizeRegisterUrl.href));

          if (state.registrationTerm) url.searchParams.set(state.registrationTerm, "true");

          return url.toString();
        };
        location.href = getRegisterCallbackUrl();
      },
    );
    if (typeof unsubscribeEmailRegistrationCompleted === "function") {
      allUnsubscribeFunctions.push(unsubscribeEmailRegistrationCompleted);
    }

    const unsubscribeOpenWithExternalBrowser = scmpAccount.on(
      scmpAccount.EVENT.OPEN_WITH_EXTERNAL_BROWSER,
      () => {
        setState(current => ({
          ...current,
          variant: "dialog-with-primary-close-button-only",
        }));
      },
    );
    if (typeof unsubscribeOpenWithExternalBrowser === "function") {
      allUnsubscribeFunctions.push(unsubscribeOpenWithExternalBrowser);
    }

    const socialButtonClickEvents = [
      {
        event: scmpAccount.EVENT.APPLE_LOGIN_BUTTON_CLICKED,
        loginMethod: "Apple",
      },
      {
        event: scmpAccount.EVENT.GOOGLE_LOGIN_BUTTON_CLICKED,
        loginMethod: "Google",
      },
      {
        event: scmpAccount.EVENT.FACEBOOK_LOGIN_BUTTON_CLICKED,
        loginMethod: "Facebook",
      },
    ] as const;
    socialButtonClickEvents.forEach(({ event, loginMethod }) => {
      const unsubscribeSocialButtonClicked = scmpAccount.on(event, () => {
        if (!state.wallType) return;
        safeSetLocalStorage(state.wallType, loginMethod);
      });
      if (typeof unsubscribeSocialButtonClicked === "function") {
        allUnsubscribeFunctions.push(unsubscribeSocialButtonClicked);
      }
    });

    setUnsubscribeFunctions(allUnsubscribeFunctions);
  }, [
    setState,
    state.destination,
    state.registrationTerm,
    state.wallType,
    updateRegisterEmailCookie,
  ]);

  const detachEventHandlers = useCallback(() => {
    if (unsubscribeFunctions.length === 0) return;
    unsubscribeFunctions.forEach(unsubscribeFunction => unsubscribeFunction());
    setUnsubscribeFunctions([]);
  }, [unsubscribeFunctions]);

  return {
    attachEventHandlers,
    detachEventHandlers,
  };
};

export const useInitializeScmpLoginFormWithEventHandlers = () => {
  const { loginDialogState: state } = useLoginDialogStateHelper();
  const { attachEventHandlers, detachEventHandlers } = useScmpAccountEventHandlers();
  const latestDetachEventHandlers = useSyncedRef(detachEventHandlers);

  const reference = useCallback(
    (container: HTMLDivElement) => {
      if (!state.isVisible || !container) {
        latestDetachEventHandlers.current();
        return;
      }

      scmpAccount.init({
        APPLICATION: state.application,
        DESTINATION: state.destination,
      });

      const isInWebView = getIsInWebView();

      scmpAccount.initLoginWidget(container.id, {
        destination: state.destination,
        emailLoginTitle: state.emailLoginTitle,
        isInWebView,
        loginDescription: state.description,
        registerSource: state.wallType === "regi" ? "loginwall" : "",
        thirdPartySignInOptions: {
          withApple: true,
          withFacebook: true,
          withGoogle: !isInWebView,
        },
        title: state.title,
      });

      attachEventHandlers();
    },
    [
      attachEventHandlers,
      latestDetachEventHandlers,
      state.application,
      state.description,
      state.destination,
      state.emailLoginTitle,
      state.isVisible,
      state.title,
      state.wallType,
    ],
  );

  return {
    reference,
  };
};
