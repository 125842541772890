import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.async-iterator.for-each.js";
import "core-js/modules/esnext.iterator.for-each.js";
import _defineProperty from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.includes.js";
import { useRouter } from "next/router";
import qs from "qs";
import { useCallback } from "react";
export const useRouterReplaceWithSanitizedKeys = () => {
  const {
    replace
  } = useRouter();
  return useCallback(function (sanitizedKeys, url, options) {
    let extraQueryString = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const {
      restOfTheQueryString
    } = sanitizedParametersByKeys(sanitizedKeys, window.location.search);
    const asPath = new URL(location.href);
    asPath.search = qs.stringify(_objectSpread(_objectSpread({}, restOfTheQueryString), extraQueryString));
    return replace(url, asPath.toString(), options);
  }, [replace]);
};
export const sanitizedParametersByKeys = (keys, parameters) => {
  const parsedQueryString = qs.parse(parameters, {
    ignoreQueryPrefix: true
  });
  const sanitizedQueryString = {};
  const restOfTheQueryString = {};
  for (const queryKey of Object.keys(parsedQueryString)) {
    if (keys.includes(queryKey)) {
      sanitizedQueryString[queryKey] = parsedQueryString[queryKey];
    } else {
      restOfTheQueryString[queryKey] = parsedQueryString[queryKey];
    }
  }
  return {
    restOfTheQueryString,
    sanitizedQueryString
  };
};